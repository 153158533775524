<template>
  <esmp-modal
    v-model="isShowed"
    title="Добавление базы данных"
  >
    <validation-observer
      v-if="isShowed"
      ref="modalSetConnectionForm"
      :key="isShowed"
    >
      <validation-provider
        rules="required"
        :name="`«Название базы данных»`"
        v-slot="v"
        tag="div"
        class="s-database__field"
      >
        <esmp-input
          v-model="currentDatabase.name"
          label="Название базы данных"
          :error-message="v.errors.length ? v.errors[0] : ''"
        />
      </validation-provider>
      <validation-provider
        rules="required"
        :name="`«Ссылка на подключение»`"
        v-slot="v"
        tag="div"
        class="s-database__field"
      >
        <esmp-input
          v-model="currentDatabase.url"
          label="Ссылка на подключение"
          :error-message="v.errors.length ? v.errors[0] : ''"
        />
      </validation-provider>
      <validation-provider
        rules="required"
        :name="`«Логин»`"
        v-slot="v"
        tag="div"
        class="s-database__field"
      >
        <esmp-input
          v-model="currentDatabase.user"
          label="Логин"
          :error-message="v.errors.length ? v.errors[0] : ''"
        />
      </validation-provider>
      <validation-provider
        rules="required"
        :name="`«Пароль»`"
        v-slot="v"
        tag="div"
        class="s-database__field"
      >
        <esmp-input
          v-model="currentDatabase.password"
          label="Пароль"
          hint="* Пароль скрыт"
          :options="{type: 'password'}"
          :error-message="v.errors.length ? v.errors[0] : ''"
        />
      </validation-provider>
    </validation-observer>
    <template #footer>
      <esmp-button @click="saveDatabaseConnection">
        Добавить
      </esmp-button>
      <esmp-button view="outline" @click="isShowed = false">
        Отменить
      </esmp-button>
    </template>
  </esmp-modal>
</template>

<script>
import { mapActions } from 'vuex';
import CryptoJS from 'crypto-js'; // TODO: оптимизировать импорт, это очень тяжело
import { DEFAULT_DATABASE_OBJECT, SECRET_PASSWORD } from '@/constants/database';

export default {
  name: 'ModalSetConnection',
  model: {
    prop: 'showed',
    event: 'input',
  },
  props: {
    showed: {
      type: Boolean,
      required: true,
    },
    currentDatabase: {
      type: Object,
      default: DEFAULT_DATABASE_OBJECT,
    },
  },
  computed: {
    isShowed: {
      get() {
        return this.showed;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    saveDatabaseConnection() {
      this.$refs.modalSetConnectionForm.validate().then((valid) => {
        if (valid) {
          this.setLoading({ key: 'app', value: true });
          const key = CryptoJS.enc.Utf8.parse(SECRET_PASSWORD);
          const encrypted = CryptoJS.AES.encrypt(this.currentDatabase.password, key, { mode: CryptoJS.mode.ECB });
          const connectionParams = {
            name: this.currentDatabase.name,
            url: this.currentDatabase.url,
            user: this.currentDatabase.user,
            passwordEncrypted: encrypted.toString(),
          };

          return this.$API.database.saveDatabaseConnection(connectionParams)
            .then(() => {
              this.$EsmpNotify.$show('Подключение к БД успешно сохранено', 'success');
              connectionParams.passwordEncrypted = null;
              this.$emit('get-databases', connectionParams);
              this.isShowed = false;
            })
            .finally(() => {
              this.setLoading({ key: 'app', value: false });
            });
        }
        this.$EsmpNotify.$show('Не заполнены обязательные поля', 'error');
        return valid;
      });
    },
  },
};
</script>
